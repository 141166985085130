const en_US = {
    Assets__usr__content: 'USDx saving rate',
    Assets__usr: 'USR',
    goldx: 'GOLDx',
    goldx_detail: 'Token backed by physical gold',
    Yield_Markets: 'Yield Markets',
    Yield_Markets_detail: 'A yield aggregator',
    send: 'SEND',
    recive: 'RECEIVE',
    send_l: 'Send',
    recive_l: 'Receive',
    history: 'Recent Transactions',
    swap: 'SWAP',
    connect: 'Connect',
    dForceTrade: 'dForce Trade',
    slogon: 'DEX aggregator',
    no_history: 'No History',
    Contract_US: 'Contact',
    Community: 'Community',
    Resource: 'Resource',
    Insufficient_Balance: 'Insufficient Balance',
    Insufficient_Liquidity: 'Insufficient Liquidity',
    balance: 'Balance',
    Not_SUPPORTED: 'NOT SUPPORTED',
    Exchange_Market: 'Trade',
    dForce_Stablecoin: 'Assets',
    Portal: 'An indexed stablecoin',
    Instant_Swap_of_Stable_Assets: 'Stable assets swap',
    DF_token_distribute_system: 'DF token distribution',
    Governance: 'Governance',
    pool_title: 'Which pool would you like to stake?',
    pool_name: 'Balancer USDx/DF Pool',
    // pool_tips: 'Stake BPT to earn DF',
    // tips_stake: 'Stake',
    // tips_earn: 'to earn',
    // tips_DF: 'DF',
    dToken:'Champagne Tower Pool',
    DF_DF:'Singularity Pool',
    DF_USDx_USDC:'Champagne Tower Pool',
    DF_dUSD:'Champagne Tower Pool',
    DF_GOLDx_USDx:'$GOLDx Rush Pool',
    dToken_APY:'Champagne Tower dToken',
    DF_APY:'Singularity',
    USDx_USDC_APY:'Champagne Tower Uniswap',
    GOLDx_USDx_APY:'$GOLDx Rush',
    Pool:'Pool',
    MiningAPY:'Mining APY',
    YieldingAPY:'Yielding APY',
    CompoundAPY:'Combined APY',
    open: 'Open',
    Staking_title: 'dForce (DF) Liquidity Mining',
    Staking_subTitle: 'Participate in dForce liquidity mining by staking and earn DF rewards',
    Staking_dUSD_subTitle: 'Participate in dForce liquidity mining by staking and earn DF/SFG rewards',
    Your_Balance: 'Your Balance',
    Currently_Staked: 'Currently Staked',
    Available_to_Claim: 'Available to Claim',
    ROI:'ROI',
    APY:'Earning interest in APY ',
    Amount: 'Amount',
    MAX: 'MAX',
    STAKE: 'STAKE',
    UNSTAKE: 'UNSTAKE',
    Claim_DF: 'Claim DF',
    Claim_SFG: 'Claim SFG',
    Exit_Claim_and_Unstake: 'Exit: Claim and Unstake',
    unstake_lock_title1: 'Lock-up period for pledged assets will end by:'
}
export default en_US;